<template>
  <div class="index">
    <navigation msg="岗位发布"></navigation>
    <div class="title">
      <div class="left">试试电脑端快速发布</div>
      <div class="right"><van-icon name="arrow" /></div>
    </div>
    <div class="step">
      <div class="step_1">4</div>
      <div class="step_xian"></div>
      <div class="step_2">5</div>
    </div>
    <div class="step_text">
      <div class="step_text_1">岗位薪资待遇</div>
      <div class="step_text_2">生成岗位报价</div>
    </div>
    <div class="item3">
      <div class="label"><span>*</span>薪资组成结构</div>
      <div class="name">
        <div>
          <div class="left">基本组成</div>
          <div class="right">
            <van-checkbox-group
              v-model="form.salary_constitute"
              direction="horizontal"
              @change="onChange1"
            >
              <van-checkbox disabled name="工资" checked-color="#EB4E36"
                >工资</van-checkbox
              >
              <van-checkbox name="五险" checked-color="#EB4E36"
                >五险</van-checkbox
              >
              <van-checkbox name="一金" checked-color="#EB4E36"
                >一金</van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="name rem28" v-if="isShow">
        五险比例： 五险 = 工资 *
        <span @click="show1 = true" class="span"
          >{{ form.insurance_fund.guaranteeValue1 || 0 }}%</span
        >
      </div>
      <van-popup v-model="show1" round position="bottom">
        <van-picker
          @confirm="onConfirm1"
          title=""
          show-toolbar
          value-key="label"
          :columns="columns1"
          @cancel="show1 = false"
        />
      </van-popup>
      <div class="name rem28" v-if="isShow1">
        一金比例： 一金 = 工资 *
        <span @click="show2 = true" class="span"
          >{{ form.insurance_fund.guaranteeValue2 || 0 }}%</span
        >
        *
        <span @click="show3 = true" class="span"
          >{{ form.insurance_fund.guaranteeValue3 || 0 }}%</span
        >
      </div>
      <van-popup v-model="show2" round position="bottom">
        <van-picker
          @confirm="onConfirm2"
          title=""
          show-toolbar
          value-key="label"
          :columns="columns2"
          @cancel="show2 = false"
        />
      </van-popup>
      <van-popup v-model="show3" round position="bottom">
        <van-popup v-model="show3" round position="bottom">
          <van-picker
            @confirm="onConfirm3"
            title=""
            show-toolbar
            value-key="label"
            :columns="columns3"
            @cancel="show3 = false"
          />
        </van-popup>
      </van-popup>
      <div class="name">
        <div>
          <div class="left">奖金</div>
          <div class="right">
            <van-checkbox-group
              v-model="form.salary_constitute"
              direction="horizontal"
            >
              <van-checkbox name="季度奖" checked-color="#EB4E36"
                >季度奖</van-checkbox
              >
              <van-checkbox name="年终奖" checked-color="#EB4E36"
                >年终奖</van-checkbox
              >
              <van-checkbox name="其他奖金" checked-color="#EB4E36"
                >其他奖金</van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="item2" v-if="isShow2">
        <div class="label"><span>*</span>年终奖比例：</div>
        <div class="name" @click="show4 = true">
          <div :style="year_end_bonus_ratio ? 'color: #000' : ''">
            {{ year_end_bonus_ratio || "请选择年终奖比例" }}
          </div>
          <van-icon name="arrow" />
        </div>
      </div>
      <van-popup v-model="show4" round position="bottom">
        <van-picker
          @confirm="onConfirm4"
          title=""
          show-toolbar
          value-key="label"
          :columns="columns4"
          @cancel="show4 = false"
        />
      </van-popup>
      <div class="item1" v-if="isShow3">
        <div class="label"><span>*</span>其他奖金说明：</div>
        <div class="name">
          <van-field
            v-model="form.other_bonuses"
            placeholder="请输入其他奖金说明："
          />
        </div>
      </div>
      <div class="name">
        <div>
          <div class="left">绩效考核</div>
          <div class="right">
            <van-checkbox-group
              v-model="form.salary_constitute"
              direction="horizontal"
            >
              <van-checkbox checked-color="#EB4E36" name="绩效"
                >绩效</van-checkbox
              >
              <van-checkbox checked-color="#EB4E36" name="团队绩效"
                >团队绩效</van-checkbox
              >
              <van-checkbox checked-color="#EB4E36" name="专业培训"
                >专业培训</van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
      </div>

      <div class="name">
        <div>
          <div class="left">福利待遇</div>
        </div>
      </div>
      <div>
        <van-checkbox-group
          v-model="form.welfare_treatment"
          direction="horizontal"
        >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="通讯补贴"
            >通讯补贴</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="交通补贴"
            >交通补贴</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="节日福利"
            >节日福利</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="地区补贴"
            >地区补贴</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="定期体检"
            >定期体检</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="商业保险"
            >商业保险</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="补充公积金"
            >补充公积金</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="补充医疗保险"
            >补充医疗保险</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="意外险"
            >意外险</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="带薪年假"
            >带薪年假</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="餐补"
            >餐补</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="员工旅游"
            >员工旅游</van-checkbox
          >
          <van-checkbox
            style="margin-bottom: 0.3rem"
            checked-color="#EB4E36"
            name="股票期权"
            >股票期权</van-checkbox
          >
        </van-checkbox-group>
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>五险一金缴纳模式</div>
      <div class="name" @click="show5 = true">
        <div :style="payment_mode ? 'color: #000' : ''">
          {{ payment_mode || "请选择五险一金的模式" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show5" round position="bottom">
      <van-picker
        @confirm="onConfirm5"
        title=""
        show-toolbar
        value-key="label"
        :columns="columns5"
        @cancel="show5 = false"
      />
    </van-popup>
    <div class="item1" v-if="form.payment_mode == 2">
      <div class="label"><span>*</span>缴纳基数</div>
      <div class="name">
        <van-field
          v-model="form.payment_base"
          placeholder="请输入输入缴纳基数"
        />
      </div>
    </div>
    <div class="item2">
      <div class="label">其他补助项目情况</div>

      <div v-for="(item, index) in other_subsidy_projects" :key="index">
        <div class="name" @click="tj_show6(index)">
          <div :style="item.n1 ? 'color: #000' : ''">
            {{ item.n1 || "时间" }}
          </div>
          <van-icon name="arrow" />
        </div>
        <div class="name">
          <van-field v-model="item.n2" placeholder="补助内容" />
        </div>
        <div class="name">
          <van-field v-model="item.n3" placeholder="金额（RMB）" />
        </div>
      </div>
      <div class="solt">
        <div class="btn1" @click="addSkillsName">+ 新增其他补助情况</div>
        <div
          class="btn2"
          v-if="other_subsidy_projects.length > 1"
          @click="other_subsidy_projects.splice(index, 1)"
        >
          删除补助情况
        </div>
      </div>
    </div>
    <van-popup v-model="show6" round position="bottom">
      <van-picker
        @confirm="onConfirm6"
        title="选择时间"
        show-toolbar
        :columns="columns6"
        @cancel="show6 = false"
      />
      <!-- <van-datetime-picker
        type="date"
        title="选择年月日"
        @confirm="onConfirm6"
        @cancel="show6 = false"
      /> -->
    </van-popup>
    <div class="item2">
      <div class="label">其他福利项目情况</div>

      <div v-for="(item, index) in other_welfare_programs" :key="index">
        <div class="name" @click="tj_show7(index)">
          <div :style="item.n1 ? 'color: #000' : ''">
            {{ item.n1 || "时间" }}
          </div>
          <van-icon name="arrow" />
        </div>
        <div class="name">
          <van-field v-model="item.n2" placeholder="其他福利项目情况" />
        </div>
        <div class="name">
          <van-field v-model="item.n3" placeholder="金额（RMB）" />
        </div>
      </div>
      <div class="solt">
        <div class="btn1" @click="addSkillsName1">+ 新增其他福利项目</div>
        <div
          class="btn2"
          v-if="other_welfare_programs.length > 1"
          @click="other_welfare_programs.splice(index, 1)"
        >
          删除福利项目
        </div>
      </div>
    </div>
    <van-popup v-model="show7" round position="bottom">
      <!-- <van-datetime-picker
        type="date"
        title="选择年月日"
        @confirm="onConfirm7"
        @cancel="show7 = false"
      /> -->
      <van-picker
        @confirm="onConfirm7"
        title="选择时间"
        show-toolbar
        :columns="columns7"
        @cancel="show7 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>薪资范围起薪点</div>
      <div class="name" @click="show8 = true">
        <div :style="salary_min ? 'color: #000' : ''">
          {{ salary_min || "请选择薪资范围起薪点" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show8" round position="bottom">
      <van-picker
        @confirm="onConfirm8"
        title=""
        show-toolbar
        value-key="label"
        :columns="columns8"
        @cancel="show8 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>薪资范围最高点</div>
      <div class="name" @click="show9 = true">
        <div :style="salary_max ? 'color: #000' : ''">
          {{ salary_max || "请选择薪资范围最高点" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show9" round position="bottom">
      <van-picker
        @confirm="onConfirm9"
        title=""
        show-toolbar
        value-key="label"
        :columns="columns9"
        @cancel="show9 = false"
      />
    </van-popup>

    <div class="item2">
      <div class="label">
        <span>*</span>企业最高可提供薪资 <i>（针对特别优秀人才）</i>
      </div>
      <div class="name" @click="show10 = true">
        <div :style="highest_salary ? 'color: #000' : ''">
          {{ highest_salary || "企业最高可提供薪资" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show10" round position="bottom">
      <van-picker
        @confirm="onConfirm10"
        title=""
        show-toolbar
        value-key="label"
        :columns="columns10"
        @cancel="show10 = false"
      />
    </van-popup>

    <div class="item2">
      <div class="label"><span>*</span>薪资范围（发薪月数）</div>
      <div class="name" @click="show11 = true">
        <div :style="salary_number ? 'color: #000' : ''">
          {{ salary_number || "请选择薪资范发薪月数" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show11" round position="bottom">
      <van-picker
        @confirm="onConfirm11"
        title=""
        show-toolbar
        value-key="label"
        :columns="columns11"
        @cancel="show11 = false"
      />
    </van-popup>

    <!-- <div class="item2">
      <div class="label"><span>*</span>建议起薪点</div>
      <div class="name">
        <div>请选择薪资范发薪月数</div>
        <van-icon name="arrow" />
      </div>
    </div> -->

    <div style="height: 2rem"></div>
    <div class="footer">
      <div class="btn1" @click="save">保存</div>
      <div class="btn2" @click="back">上一步</div>
      <div class="btn3" @click="toPage">下一步</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      showPicker: false,
      //   时间选择器
      currentDate: new Date(),
      changeDate: new Date(),
      value: "",
      value2: "",
      isShow: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
      show12: false,
      show13: false,
      show14: false,
      show15: false,
      columns1: [],
      columns2: [],
      columns3: [],
      columns4: [
        {
          label: "平均幅度高于年薪10%",
          value: 0,
        },
        {
          label: "平均幅度高于年薪20%",
          value: 1,
        },
        {
          label: "平均幅度高于年薪30%",
          value: 2,
        },
      ],
      columns5: [
        {
          label: "按照全额工资缴纳",
          value: 1,
        },
        {
          label: "不按照全额工资缴纳",
          value: 2,
        },
      ],
      columns6: ["每年", "每月", "每周", "每日"],
      columns7: ["每年", "每月", "每周", "每日"],
      columns8: [],
      columns9: [],
      columns10: [],
      columns11: [],
      year_end_bonus_ratio: "",
      payment_mode: "",
      other_subsidy_projects: [
        {
          n1: "",
          n2: "",
          n3: "",
        },
      ], //其他项目补助情况 ，三个框用 , 隔开 每一条结束用 ；隔开
      other_welfare_programs: [
        {
          n1: "",
          n2: "",
          n3: "",
        },
      ], //其他福利补助情况 ，三个框用 , 隔开 每一条结束用 ；隔开
      salary_min: "",
      salary_max: "",
      highest_salary: "",
      salary_number: "",
      form: {
        payment_base: "",
        welfare_treatment: [], //福利待遇 ，多个用 , 分割
        other_subsidy_projects: "",
        other_welfare_programs: "",
        payment_mode: "", //社保缴纳模式  1-全额 2-非全额
        highest_salary: "", //企业最高可提供薪资
        recruit: 1,
        job_losses: "0",

        depth_report: "0",
        serve_price: 0,
        recruit_number: "",
        salary_constitute: ["工资"], //  工资组成
        insurance_fund: {
          guaranteeValue1: "", // 五险
          guaranteeValue2: "", // 一金
          guaranteeValue3: "", // 一金
        },
        salary_min: "8k", // 薪资范围下限
        salary_max: "", // 薪资范围上限
        salary_number: 12, // 薪资月数
        year_end_bonus_ratio: "",
        other_bonuses: "",
        pay_attraction: 0,
        // post_price: "", // 悬赏总金额
        // basics: "", // 单人悬赏金额
        // rewards: "", //招聘服务费
      },
      tj_show6val: "",
    };
  },
  mounted() {
    for (let index = 1; index <= 100; index++) {
      this.columns8.push({
        label: `${index}K`,
        value: index,
      });
    }
    for (let index = 12; index <= 24; index++) {
      this.columns11.push({
        label: `${index}月`,
        value: index,
      });
    }
    for (let index = 100; index >= 20; index--) {
      this.columns1.push({
        label: `${index}%`,
        value: index,
      });
      this.columns2.push({
        label: `${index}%`,
        value: index,
      });
    }
    for (let index = 8; index >= 0; index--) {
      this.columns3.push({
        label: `${index}%`,
        value: index,
      });
    }
    if (Object.keys(this.forms).length != 0) {
      Object.keys(this.forms).forEach((key) => {
        this.form[key] = this.forms[key];
      });
      if (this.form.welfare_treatment == undefined) {
        this.form.welfare_treatment = [];
      } else {
        this.form.welfare_treatment = this.form.welfare_treatment.split(",");
      }
      if (this.form.other_subsidy_projects != undefined) {
        this.other_subsidy_projects = [];
      }
      if (this.form.other_welfare_programs != undefined) {
        this.other_welfare_programs = [];
      }
      this.form.other_subsidy_projects = this.form.other_subsidy_projects
        ? this.form.other_subsidy_projects
        : "";
      this.form.other_welfare_programs = this.form.other_welfare_programs
        ? this.form.other_welfare_programs
        : "";
      this.form.other_subsidy_projects.split(";").forEach((item, index) => {
        if (this.form.other_subsidy_projects.split(";").length - 1 != index) {
          let arr = item.split(",");
          this.other_subsidy_projects.push({
            n1: arr[0],
            n2: arr[1],
            n3: arr[2],
          });
        }
      });
      this.form.other_welfare_programs.split(";").forEach((item, index) => {
        if (this.form.other_welfare_programs.split(";").length - 1 != index) {
          let arr = item.split(",");
          this.other_welfare_programs.push({
            n1: arr[0],
            n2: arr[1],
            n3: arr[2],
          });
        }
      });
      if (!this.form.salary_constitute) {
        this.form.salary_constitute = ["工资"];
        this.form.insurance_fund = {
          guaranteeValue1: "", // 五险
          guaranteeValue2: "", // 一金
          guaranteeValue3: "", // 一金
        };
      }
      let a1 = this.columns4.findIndex((item) => {
        return item.value == this.form.year_end_bonus_ratio;
      });
      this.year_end_bonus_ratio = this.columns4[a1].label;
      let a2 = this.columns5.findIndex((item) => {
        return item.value == this.form.payment_mode;
      });
      this.payment_mode = this.columns5[a2].label;
      this.salary_min = this.form.salary_min;
      this.salary_max = this.form.salary_max;
      this.highest_salary = this.form.highest_salary;
      this.salary_number = this.form.salary_number + "月";
    }
  },
  methods: {
    save() {
      this.form.welfare_treatment = this.form.welfare_treatment.join(",");
      let arr = "";
      this.other_subsidy_projects.forEach((item, index) => {
        arr += item.n1 + "," + item.n2 + "," + item.n3 + ";";
      });
      let arr1 = "";
      this.other_welfare_programs.forEach((item, index) => {
        arr1 += item.n1 + "," + item.n2 + "," + item.n3 + ";";
      });
      this.form.other_subsidy_projects = arr;
      this.form.other_welfare_programs = arr1;
      this.form = Object.assign({}, this.forms, this.form);
      this.$store.commit("SET_POST", this.form);
      this.$toast("保存成功");
    },
    // 添加技能名称
    addSkillsName() {
      let obj = {
        n1: "",
        n2: "",
        n3: "",
      };
      this.other_subsidy_projects.push(obj);
    },
    addSkillsName1() {
      let obj = {
        n1: "",
        n2: "",
        n3: "",
      };
      this.other_welfare_programs.push(obj);
    },
    tj_show6(index) {
      this.show6 = true;
      this.tj_show6val = index;
    },
    tj_show7(index) {
      this.show7 = true;
      this.tj_show6val = index;
    },
    onConfirm1(value) {
      this.form.insurance_fund.guaranteeValue1 = value.value;
      this.show1 = false;
    },
    onConfirm2(value) {
      this.form.insurance_fund.guaranteeValue2 = value.value;
      this.show2 = false;
    },
    onConfirm3(value) {
      this.form.insurance_fund.guaranteeValue3 = value.value;
      this.show3 = false;
    },
    onConfirm4(value) {
      this.year_end_bonus_ratio = value.label;
      this.form.year_end_bonus_ratio = value.value;
      this.show4 = false;
    },
    onConfirm5(value) {
      this.payment_mode = value.label;
      this.form.payment_mode = value.value;
      this.show5 = false;
    },

    // 时间弹出框  确认按钮
    onConfirm6(dat) {
      // var year = dat.getFullYear();
      // var mon =
      //   dat.getMonth() + 1 < 10
      //     ? "0" + (dat.getMonth() + 1)
      //     : dat.getMonth() + 1;
      // var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();
      // var hour = dat.getHours() < 10 ? "0" + dat.getHours() : dat.getHours();
      // var min =
      //   dat.getMinutes() < 10 ? "0" + dat.getMinutes() : dat.getMinutes();
      // var seon =
      //   dat.getSeconds() < 10 ? "0" + dat.getSeconds() : dat.getSeconds();
      // var newDate = year + "-" + mon + "-" + data;
      this.other_subsidy_projects[this.tj_show6val].n1 = dat;
      this.show6 = false;
    },
    // 时间弹出框  确认按钮
    onConfirm7(dat) {
      // var year = dat.getFullYear();
      // var mon =
      //   dat.getMonth() + 1 < 10
      //     ? "0" + (dat.getMonth() + 1)
      //     : dat.getMonth() + 1;
      // var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();
      // var hour = dat.getHours() < 10 ? "0" + dat.getHours() : dat.getHours();
      // var min =
      //   dat.getMinutes() < 10 ? "0" + dat.getMinutes() : dat.getMinutes();
      // var seon =
      //   dat.getSeconds() < 10 ? "0" + dat.getSeconds() : dat.getSeconds();
      // var newDate = year + "-" + mon + "-" + data;
      this.other_welfare_programs[this.tj_show6val].n1 = dat;
      this.show7 = false;
    },
    onConfirm8(value) {
      this.salary_min = value.label;
      this.form.salary_min = value.label;
      if (value.value < 10) {
        this.columns9 = [];
        var arr = value.value;
        for (arr; arr < Number(value.value) * 1.5; arr++) {
          this.columns9.push({
            label: Number(arr) + 1 + "k",
            value: Number(arr) + 1,
          });
        }
      } else {
        this.columns9 = [];
        var arr = value.value;
        for (arr; arr < Number(value.value) + 15; arr++) {
          this.columns9.push({
            label: Number(arr) + 1 + "k",
            value: Number(arr) + 1,
          });
        }
      }
      this.show8 = false;
    },
    onConfirm9(value) {
      this.salary_max = value.label;
      this.form.salary_max = value.label;
      let str = value.label;
      str = str.slice(0, str.length - 1);
      var arr = str;
      this.columns10 = [];
      //企业最高可提供薪资上浮30%
      for (arr; arr < Number(str) * 1.3; arr++) {
        this.columns10.push({
          label: Number(arr) + 1 + "k",
          value: Number(arr) + 1,
        });
      }
      this.show9 = false;
    },
    onConfirm10(value) {
      this.highest_salary = value.label;
      this.form.highest_salary = value.label;
      this.show10 = false;
    },
    onConfirm11(value) {
      this.salary_number = value.label;
      this.form.salary_number = value.value;
      this.show11 = false;
    },
    onChange1(val) {
      let arr = val;
      let obj = this.form.insurance_fund;
      if (arr.indexOf("五险") != -1) {
        this.isShow = true;
        obj.guaranteeValue1 = 100;
      } else {
        this.isShow = false;
        obj.guaranteeValue1 = "";
      }
      if (arr.indexOf("一金") != -1) {
        this.isShow1 = true;
        obj.guaranteeValue2 = 100;
        obj.guaranteeValue3 = 8;
      } else {
        this.isShow1 = false;
        obj.guaranteeValue2 = "";
        obj.guaranteeValue3 = "";
      }
      if (arr.indexOf("年终奖") != -1) {
        this.isShow2 = true;
      } else {
        this.isShow2 = false;
      }
      if (arr.indexOf("其他奖金") != -1) {
        this.isShow3 = true;
      } else {
        this.isShow3 = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    toPage() {
      this.form.welfare_treatment = this.form.welfare_treatment.join(",");
      let arr = "";
      this.other_subsidy_projects.forEach((item, index) => {
        arr += item.n1 + "," + item.n2 + "," + item.n3 + ";";
      });
      let arr1 = "";
      this.other_welfare_programs.forEach((item, index) => {
        arr1 += item.n1 + "," + item.n2 + "," + item.n3 + ";";
      });
      this.form.other_subsidy_projects = arr;
      this.form.other_welfare_programs = arr1;
      this.form = Object.assign({}, this.forms, this.form);
      this.$store.commit("SET_POST", this.form);
      this.$router.push({
        path: "/step5",
      });
    },
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
      poststatus: (state) => state.poststatus,
    }),
  },
};
</script>

<style lang='scss' scoped>
.index {
  background-color: #fff;
}
.index /deep/ .van-picker-column__item--selected {
  color: red !important;
}
.index /deep/ .van-picker__confirm {
  color: red !important;
}
.van-cell {
  padding: 10px 0px;
}
.title {
  display: flex;

  justify-content: space-between;
  height: 0.88rem;
  padding: 0 0.28rem;
  line-height: 0.88rem;
  background: #fff0e5;
  .left,
  .right {
    margin-bottom: 0.2r;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
  }
}
.step {
  margin-top: 0.32rem;
  padding: 0 1.12rem;
  display: flex;
  align-items: center;
  .step_1,
  .step_2 {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
    background: #ff3b24;
    text-align: center;
    line-height: 0.42rem;
  }
  .step_xian {
    flex: 1;
    height: 8px;
    background: linear-gradient(270deg, #ffc4bd 0%, #ff3b24 100%);
  }
  .step_1 {
    transform: translateX(0.02rem);
    color: #fff;
  }
  .step_2 {
    transform: translateX(-0.02rem);
    color: #fff;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #ff3b24;
    opacity: 0.3;
  }
}
.step_text {
  margin-bottom: 0.8rem;
  margin-top: 0.21rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.35rem;
  .step_text_1 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .step_text_2 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #acb5c8;
  }
}
.index /deep/ input::placeholder {
  font-size: 0.3rem;
}
.rem28 {
  font-size: 0.28rem;
}
.span {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem 0;
  border: 1px solid #dddfe5;
  flex: 1;
}
//输入框
.item1 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    display: flex;
    align-items: center;

    height: 1.12rem;
  }
}
//选择框
.item2 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #ff3b24;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #c8c8c8;
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
// 多选择框
.item3 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #6277da;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      flex: 1;
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      .right {
        color: #c8c8c8;
        margin-right: 0.15rem;
      }
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
.footer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.29rem;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1.56rem;
  border-top: 0.02rem solid #f2f2f2;
  background: #ffffff;
  .btn1 {
    text-align: center;
    line-height: 0.95rem;
    width: 1.97rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn2 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.36rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn3 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.36rem;
    height: 0.95rem;
    background: #ff3b24;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>